<template>
    <div class="zt-page-content" ref="load">
        <div v-if="item" class="container">
            <div class="detail-top">
                <div class="top flex">
                    <div class="info-img">
                        <img src="@/assets/images/desgin/icon_series.png">
                    </div>
                    <div class="detail-info flex-1">
                        <div class="info-name ellipsis1">
                            <b>{{item.name}}</b>
                        </div>
                        <div class="style">
                            <span v-if="item.style">{{item.style.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="bottom flex">
                    <div v-if="item.photos && item.photos.length>0" class="img-carousel">
                        <ItemImages :imgs="item.photos" :preUrl="IMG_URL_PRE" width="400px"></ItemImages>
                    </div>
                </div>
            </div>
            <div class="detail-middle">
                <div class="title">销售信息</div>
                <div class="dm-box flex">
                    <div>
                        <div>销售方式：
                            <span v-if="item.price_type==0">设计版权出售</span>
                            <span v-if="item.price_type==1">分成合作</span>
                            <span v-if="item.price_type==3">需求定制</span>
                            <span v-if="item.price_type==4">暂不上架</span>
                        </div>
                        <div>一口价：
                            <span class="price">￥{{item.price | priceFilt}}</span>
                        </div>
                    </div>
                    <div>
                        <div>当前状态：
                            <span v-if="item.stat==1">已上架</span>
                            <span v-if="item.stat==2">已下架</span>
                            <span v-if="item.stat==3">已删除</span>
                            <span v-if="item.stat==4">已加入回收站</span>
                            <span v-if="item.stat==5">
                                <span v-if="item.trans_stat>=6">交易完成</span>
                                <span v-else>交易中</span>
                            </span>
                        </div>
                        <div v-if="item.price_type==0">合同：
                            <el-button @click="contract_view(item)" size="small" type="text">点击查看</el-button>
                        </div>
                    </div>
                    <div>
                        <div>最后修改时间：
                            <span>{{item.updated_at | moment("YYYY/MM/DD")}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="detail-bottom">
                <div class="tab_menu flex">
                    <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="menu_item" :class="{'cur':tabIndex == index}">
                        {{item.name}}
                    </div>
                </div>
                <div v-if="tabList && tabList.length>0" class="db-box">
                    <div class="db-box-item" v-for="(item,idx) in tabList" :key="idx">
                        <div v-if="tabIndex==idx" class="flex">
                            <div class="dbb-item" @click="gotoProduct(row.id)" v-for="(row,idx1) in item.product_rows" :key="idx1">
                                <div v-if="row.photo_render && row.photo_render.length>0">
                                    <img :src="(IMG_URL_PRE || '')+row.photo_render[0]+'!width_250px'">
                                </div>
                                <div class="text ellipsis2">{{row.name}}</div>
                            </div>
                        </div>
                    </div>
                    
                </div>
                
            </div>
        </div>
    </div>
</template>
<script>
import { IMG_URL_PRE,STATIC_URL_PRE } from '@/config';
import { contractView, testHt } from '@/service/contract';
import { seriesDetail } from '@/service/design';
import ItemImages from '@/components/ItemImages.vue';
export default {
    components: { ItemImages },
    data() {
        return {
            IMG_URL_PRE,
            STATIC_URL_PRE,
            item: null,
            tabList:[
                // { name: "未分类", }
            ],
            tabIndex:0,
        }
    },
    created() {
        this.refreshItem();
    },
    mounted() {},
    methods: {
        // gotoDetail(id) {
        //     this.$router.push("/desgin/stored/single/detail/"+id);
        // },
        gotoProduct(id) { // 查看商品详情
            this.$router.push("/desgin/stored/single/detail/"+id+"?setType=1");
        },
        // 选项卡
        tab(index) {
            this.tabIndex = index;
            console.log(index);
        },
        refreshItem() { // 获取数据
            console.log('refreshItem');
            const _loading = this.$loading({
                lock: true,
                text: '拼命加载中......',
                target: this.$refs.load
            });
            seriesDetail(this.$route.params.id).then(res => {
                this.item = res;
                this.tabList = [];
                let list = [];
                if (this.item.product_rows && this.item.product_rows.length>0) {
                    this.item.product_rows.forEach(e => {
                        list.push(e);
                    });
                }
                if (this.item.sub_set_rows && this.item.sub_set_rows.length>0) {
                    this.tabList = this.item.sub_set_rows;
                    this.item.sub_set_rows.forEach(e => {
                        if (e.product_rows && e.product_rows.length>0) {
                            e.product_rows.forEach(n=>{
                                list.push(n);
                            })
                        }
                    });
                }
                this.tabList.unshift({
                    id: this.item.id,
                    parent_set_no: "",
                    name: "未分类", 
                    product_rows: this.item.product_rows
                })
                this.tabList.unshift({
                    id: 0,
                    parent_set_no: "",
                    name: "全部", 
                    product_rows: list
                })
                console.log(this.item,this.tabList);
                _loading.close();
            }).catch(err => {
                console.log(err);
                this.$message.error(err.message);
                _loading.close();
            });
        },
        contract_view(item) { // 查看合同
            if (item.contract_no) {
                window.open(contractView(item.contract_no));
            } else {
                let obj = {
                    contract_title:"设计版权购买合同",
                    content_file: item.contract_file,
                    custom_cover_page: item.custom_cover_page,
                }
                if (item.price_type==0) {
                    obj.contract_title = "设计版权购买合同";
                } else if (item.price_type==3) {
                    obj.contract_title = "需求定制合同";
                } else {
                    obj.contract_title = "合同标题";
                }
                if (obj.content_file) {
                    window.open(testHt(obj));
                }
            }
        }
    }
}
</script>
<style scoped src="../../style.css"></style>
<style scoped lang="less">
.container {
    width: 1200px;
    margin: 0 auto;
}
.detail-top {
    padding: 20px;
    background: #fff;
    border: 1px solid #E6E6E6;
    margin-bottom: 20px;
    .top {
        padding-bottom: 20px;
        .info-img {
            width: 36px;
            height: 36px;
            background: #5074EE;
            border-radius: 6px;
            overflow: hidden;
            margin-right: 12px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .info-name {
            font-size: 18px;
            color: #292929;
            line-height: 25px;
            margin-bottom: 4px;
        }
        .style {
            font-size: 14px;
            color: #4D4D4D;
        }
    }
    .bottom {
        padding: 20px 50px 0;
        border-top: 1px solid #E6E6E6;
        >div {
            font-size: 14px;
            color: #4D4D4D;
            margin-right: 45px;
            >img {
                height: 30px;
                margin-right: 12px;
            }
        }
    }
}
.detail-middle {
    padding: 20px;
    background: #fff;
    border: 1px solid #E6E6E6;
    margin-bottom: 20px;
    .title {
        font-size: 18px;
        color: #292929;
        line-height: 25px;
        margin-bottom: 14px;
    }
    .dm-box {
        >div {
            font-size: 14px;
            color: #666666;
            margin-right: 60px;
            >div {
                height: 32px;
                line-height: 32px;
                >span {
                    color: #4D4D4D;
                }
                &:first-child {
                    margin-bottom: 5px;
                }
            }
        }
        .price {
            font-size: 18px;
            color: #F66F6A;
        }
    }
}
.detail-bottom {
    background: #fff;
    border: 1px solid #E6E6E6;
    margin-bottom: 40px;
    .tab_menu {
        padding: 0 20px;
        height: 50px;
        border-bottom: 1px solid #E6E6E6;
        .menu_item {
            height: 50px;
            line-height: 50px;
            margin-right: 40px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            color: #4D4D4D;
            &.cur {
                color: #5074EE;
                border-bottom: 2px solid #5074EE;
            }
        }
    }
    .db-box {
        padding: 10px;
        .dbb-item {
            width: 212px;
            border: 1px solid #E6E6E6;
            margin: 10px;
            cursor: pointer;
            img {
                width: 210px;
                height: 210px;
            }
            .text {
                margin: 12px;
                font-size: 16px;
                color: #292929;
                line-height: 22px;
            }
        }
    }
}
</style>